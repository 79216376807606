import {
    ArrayField,
    Datagrid,
    Button,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    useNotify,
    useRefresh,
    useDataProvider,
    ReferenceManyField,
    EditButton,
    BooleanField,
    SelectInput,
    FunctionField
} from "react-admin";
import AddNewAdminButton from "./add-new-admin-button";
import AddNewAttributeButton from "./add-new-attribute-button";
import * as React from "react";

/** Button which handles the nonstandard prod admin url */
const DeleteProductionAdminButton = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const dataProvider = useDataProvider();
    const deleteAdmin = () => dataProvider.delete(
        `productions/${record.pivot.production_id}/admins`,
        { id: record.pivot.user_id }
    ).then(() => {
        notify('Admin removed', 'success')
        refresh()
    })

    return <Button label="Delete" onClick={deleteAdmin} />;
};

/** Button which refreshes rather than redirecting to index (which doesnt exist) */
const DeleteEmploymentAttributeButton = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const dataProvider = useDataProvider();
    const deleteAttribute = () => dataProvider.delete(
        `employment-attributes`,
        { id: record.id }
    ).then(() => {
        notify('Attribute removed', 'success')
        refresh()
    })

    return <Button label="Delete" onClick={deleteAttribute} />;
};


const ProductionTitle = props => {
    return <span>{props.record ? `${props.record.name}` : ''}</span>;
};

const toTitleCase = (str) => {
    return str
        .replace(/_/g, ' ')
        .replace(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
};

export const ProductionShow = props => {
    const id = props.match.params.id;

    return (
    <Show {...props} title={<ProductionTitle />}>
        <TabbedShowLayout>
            <Tab label="Production">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="address" />
                <TextField source="company.name" label="Company" />
                <FunctionField
                    label="Timesheet Template"
                    render={record => toTitleCase(record?.timesheet_pdf_template || 'Standard')}
                />
                <FunctionField
                    label="Disabled Fields"
                    render={record => {
                        if (!record.disabled_fields || record.disabled_fields.length === 0) {
                            return 'None';
                        }

                        return record.disabled_fields.map(field =>
                            field.charAt(0).toUpperCase() + field.slice(1)
                        ).join(', ');
                    }}
                />
            </Tab>
            <Tab label="Admins" path="admins">
                <ArrayField source="admins" addLabel={false}>
                    <Datagrid>
                        <TextField source="name" />
                        <TextField source="email" />
                        <DeleteProductionAdminButton />
                    </Datagrid>
                </ArrayField>
                <AddNewAdminButton />
            </Tab>
            <Tab label="Employment Fields" path="employment-fields">
                <ReferenceManyField reference="employment-attributes" target="production_id" addLabel={false}>
                    <Datagrid>
                        <TextField source="slug" label="Code" />
                        <TextField source="label" label="Name" />
                        <BooleanField source="required" label="Required" FalseIcon="br" />
                        <EditButton />
                        <DeleteEmploymentAttributeButton />
                    </Datagrid>
                </ReferenceManyField>
                <AddNewAttributeButton/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
}
